// pages/Dashboard.js
import React, { useState, useEffect } from 'react';
import SessionTimeout from './SessionTimeout';
import { Box, Button, Checkbox, CircularProgress, FormControl, FormLabel, Input, Modal, ModalBody, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Select, Stack, Table, TableCaption, TableContainer, Tbody, Td, Text, Th, Thead, Tr, useDisclosure } from '@chakra-ui/react';
import { toast, ToastContainer } from 'react-toastify';
import { FaSave } from "react-icons/fa";
import { MdAdd, MdDelete, MdEdit, MdError } from 'react-icons/md';
import { createUser, deleteUser, updateUserCamera } from '../actions/adminActions';
import { ImCross } from 'react-icons/im';
import { createNvr, deleteNvr, getNvr, updateNvr } from '../actions/cameraActions';

const NvrList = () => {

    const [nvrList, setNvrList] = useState([]);
    const [mobile, setMobile] = useState('');
    const [email, setEmail] = useState('');
    const [name, setName] = useState('');
    const [nvrId, setNvrId] = useState('');
    const [nvrChannel, setNvrChannel] = useState('');
    const [querySearch, setQuerySearch] = useState('');
    const [page, setPage] = useState(1);
    const [resultPerPage, setResultPerPage] = useState(5);
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [activeModal, setActiveModal] = useState(null);
    const [uploadStatus, setUploadStatus] = useState(null);
    const [file, setFile] = useState(null);
    const [deviceId, setDeviceId] = useState('');
    const [currentPage, setcurrentPage] = useState(1);
    const [loadingNext, setLoadingNext] = useState(false);
    const [loadingPrev, setLoadingPrev] = useState(false);
    const [prevButtonDisabled, setPrevButtonDisabled] = useState(false);
    const [nextButtonDisabled, setNextButtonDisabled] = useState(false);
    const [totalPages, setTotalPages] = useState(1);
    const [userIdToDelete, setUserIdToDelete] = useState(null);
    const [error, setError] = useState(null);

    const getConfig = async (page, name) => {
        try {
            const response = await getNvr(page, name);
            // console.log("getNvrList", response);
            setNvrList(response.data.data);
            setTotalPages(response.data.totalPages);
            setResultPerPage(response.data.resultPerPage);
        } catch (error) {
            toast.error(error.response.data.message);
            console.error('Error:', error);
            // navigate('/404');
        }
    };

    useEffect(() => {
        getConfig(page, name);
    }, []);

    const openModal = (modal, id, cameraName) => {
        // console.log("cameraId");
        setActiveModal(modal);
        setUserIdToDelete(id);
        // setSelectedCameraId(cameraId);
        // setSelectedCameraName(cameraName);
        onOpen();
    };

    const closeModal = () => {
        setActiveModal(null);
        // setActiveTab("General");
        onClose();
    };

    // pagination code

    const handleNextClick = async () => {

        const nextPage = currentPage + 1;
        setLoadingNext(true); // Show loading spinner
        try {
            await getConfig(nextPage, email, email);
            setcurrentPage(nextPage);
            // console.log(currentPage);

        } finally {
            setLoadingNext(false); // Hide loading spinner
        }

    };

    const handlePrevClick = async () => {

        const PrevPage = currentPage - 1;
        setLoadingPrev(true); // Show loading spinner
        try {
            await getConfig(PrevPage, email, email);
            setcurrentPage(PrevPage);
        } finally {
            setLoadingPrev(false); // Hide loading spinner
        }
    };

    const handleAddNvr = async () => {
        try {
            if (!name) {
                setError("Name is required!");
                return;
            } else if (!nvrId) {
                setError("Nvr ID is required!");
                return;
            } else if (!nvrChannel) {
                setError("Channel is required!");
                return;
            } else if (!email) {
                setError("Email is required!");
                return;
            }
            const addNvr = await createNvr(nvrId, name, email, nvrChannel);
            // console.log('addNvr', addNvr);
            closeModal();
            getConfig();
        } catch (error) {
            console.error('Error:', error);
        }
    }

    // Edit Functionality

    const [editableCameraId, setEditableCameraId] = useState(null);
    const [editedCamera, setEditedCamera] = useState({});

    const handleEditClick = (camera) => {
        // console.log("camera", camera);
        setEditableCameraId(camera._id);
        setEditedCamera(camera);
    };

    const handleInputChange = (field, value) => {
        setEditedCamera({ ...editedCamera, [field]: value });
    };

    const handleSave = async () => {
        // console.log("Updated Camera Data:", editedCamera);
        try {
            const updateUsersCamera = await updateNvr(editedCamera.nvrId, editedCamera.nvrName, editedCamera.email);
            setEditableCameraId(null); // Exit edit mode
            getConfig(page, deviceId);
            toast.success("Camera Updated Successfully");
        } catch (error) {
            console.error('Error:', error);
            toast.error("Error Updating Camera");
        }
    };

    const handleCancel = () => {
        setEditableCameraId(null); // Exit edit mode without saving
    };

    // Delete Functionality

    const handleDelete = async (nvrId) => {
        try {
            alert("Are you sure you want to delete this camera?");
            // console.log("nvrId", nvrId);
            const deleteAdmin = await deleteNvr(nvrId);
            getConfig(); // Refresh data after deletion
        } catch (error) {
            console.error('Error:', error);
        } finally {
            closeModal();
        }
    };

    useState(() => {
        setPrevButtonDisabled(currentPage === 1);
        setNextButtonDisabled(currentPage === totalPages);
        // fetchCameraList(currentPage);
    }, [currentPage, totalPages]);


    return (
        <>
            <Box p={8} mx={20} display={'flex'} flexDirection={'column'}>
                <ToastContainer />
                <SessionTimeout timeoutDuration={1800000} />

                <Box mt={4} mb={2}>
                    <Text
                        sx={{
                            color: "var(--primary-txt, #141E35)",
                            fontFamily: "Inter",
                            fontSize: "4xl",
                            fontStyle: "normal",
                            fontWeight: "700",
                            lineHeight: "normal",
                            textTransform: "capitalize",
                            textAlign: "left",
                        }}
                    >
                        Nvr List
                    </Text>
                </Box>

                <Stack
                    direction={['column', 'row']} // Column on mobile, row on larger screens
                    // justify="flex-end"
                    align="center"
                    spacing={4} // Space between Input and Button
                    mb={1}
                >
                    <Input
                        value={deviceId}
                        onChange={(e) => setDeviceId(e.target.value)}
                        placeholder="Enter NVR Name"
                        size="md"
                        maxWidth="200px"
                        focusBorderColor="green.400" // Custom border color on focus
                        _focus={{
                            boxShadow: 'none', // Remove default shadow
                            borderColor: 'green.400', // Custom border color on focus
                        }}
                    />
                    <Button
                        onClick={() => { getConfig(page, deviceId) }}
                        colorScheme='blue'
                        variant='outline'
                        size='md' // Changed to 'md' for better alignment
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => openModal('Add Camera')}
                        colorScheme='green'
                        variant='outline'
                        size='md' // Changed to 'md' for better alignment
                    >
                        ADD
                    </Button>
                </Stack>
                <TableContainer
                    width="100%"
                    // maxW="1200px"
                    mx="auto"
                    mt="4"
                    // border="1px"
                    // borderColor="gray.200"
                    boxShadow={"0px 5px 22px 0px rgba(0, 0, 0, 0.04)"}
                    borderRadius="md">
                    <Table>
                        {/* <TableCaption>Your Installed Camera List</TableCaption> */}
                        <Thead bg="gray.100">
                            <Tr>
                                <Th>Sr.No.</Th>
                                <Th>NvrID</Th>
                                <Th>Name</Th>
                                <Th>Channel</Th>
                                <Th>Email</Th>
                                <Th>Edit/Delete</Th>
                            </Tr>
                        </Thead>
                        <Tbody>
                            {nvrList && nvrList.length > 0 ? (
                                nvrList.map((camera, index) => (
                                    <Tr key={camera._id}>
                                        <Td>
                                            {index + 1 + (currentPage - 1) * resultPerPage}
                                        </Td>
                                        <Td>
                                            {camera.nvrId}
                                        </Td>
                                        <Td>
                                            {editableCameraId === camera._id ? (
                                                <Input
                                                    value={editedCamera.nvrName || ''}
                                                    onChange={(e) => handleInputChange('nvrName', e.target.value)}
                                                />
                                            ) : (
                                                camera.nvrName
                                            )}
                                        </Td>
                                        <Td>
                                            {camera.channel}
                                        </Td>
                                        <Td>
                                            {editableCameraId === camera._id ? (
                                                <Input
                                                    value={editedCamera.email || ''}
                                                    onChange={(e) => handleInputChange('email', e.target.value)}
                                                />
                                            ) : (
                                                camera.email
                                            )}
                                        </Td>
                                        <Td textAlign="center" display={'flex'}>
                                            {editableCameraId === camera._id ? (
                                                <>
                                                    <Button variant="outline" onClick={handleSave} mr={1} color='#603eb7'>
                                                        <FaSave />
                                                    </Button>
                                                    <Button
                                                        variant="outline"
                                                        colorScheme="red"
                                                        onClick={handleCancel}
                                                    >
                                                        <ImCross />
                                                    </Button>
                                                </>
                                            ) : (
                                                <Box>
                                                    <Button
                                                        variant="outline"
                                                        onClick={() => handleEditClick(camera)}
                                                        color='#9678e1'
                                                        mr={1}
                                                    >
                                                        <MdEdit />
                                                    </Button>
                                                    <Button variant="outline" colorScheme="red" onClick={() => handleDelete(camera.nvrId)}>
                                                        <MdDelete />
                                                    </Button>
                                                </Box>
                                            )}
                                        </Td>
                                    </Tr>
                                ))
                            ) : (
                                <Tr>
                                    <Td colSpan="9" textAlign="center" borderColor="gray.300">
                                        No data available
                                    </Td>
                                </Tr>
                            )}
                        </Tbody>

                    </Table>
                </TableContainer>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', marginTop: '10px' }}>
                    {currentPage}/{totalPages}
                    <Box>
                        <Button sx={{ marginRight: '5px' }} hidden={currentPage === 1} onClick={handlePrevClick} startIcon={<MdAdd />} >
                            {loadingPrev ? <CircularProgress isIndeterminate size={10} /> : 'Prev'}
                        </Button>
                        <Button hidden={currentPage === totalPages} onClick={handleNextClick} startIcon={<MdAdd />} >
                            {loadingNext ? <CircularProgress isIndeterminate size={10} /> : 'Next'}
                        </Button>
                    </Box>
                </div>

            </Box >
            <Modal
                onClose={onClose}
                isOpen={isOpen && activeModal === "Add Camera"}
                isCentered
                size={"lg"}
            >
                <ModalOverlay />
                <ModalContent
                    // bg={useColorModeValue("white", theme.colors.custom.darkModeBg)}
                    color={"black"}
                >
                    <ModalHeader
                        textAlign={"center"}
                        p={1}
                        mt={4}
                    // color={useColorModeValue(
                    //     theme.colors.custom.lightModeText,
                    //     theme.colors.custom.darkModeText
                    // )}
                    >
                        Add NVR
                    </ModalHeader>
                    <ModalBody pb={6} textAlign="center">
                        <Box
                            display="flex"
                            flexDirection="column"
                            alignItems="center"
                            justifyContent="center"
                            width="100%"
                            //   padding="10px"
                            p={1}
                        >
                            <FormControl width="350px" mt={5} isRequired>
                                <FormLabel
                                    htmlFor="device-name"
                                    textAlign="start"
                                >
                                    Nvr Name:
                                </FormLabel>
                                <Input
                                    placeholder="Nvr Name"
                                    borderColor="gray"
                                    borderRadius="10px"
                                    px={4}
                                    _placeholder={{ color: "gray.400" }}
                                    value={name}
                                    onChange={(e) => setName(e.target.value.replace(/\s/g, ""))}
                                />
                            </FormControl>
                            <FormControl width="350px" mt={5} isRequired>
                                <FormLabel
                                    htmlFor="device-name"
                                    textAlign="start"
                                >
                                    Email:
                                </FormLabel>
                                <Input
                                    placeholder="Email"
                                    borderColor="gray"
                                    borderRadius="10px"
                                    type='email'
                                    px={4}
                                    _placeholder={{ color: "gray.400" }}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value.replace(/\s/g, ""))}
                                />
                            </FormControl>
                            <FormControl width="350px" mt={5} isRequired>
                                <FormLabel
                                    htmlFor="device-name"
                                    textAlign="start"
                                >
                                    Nvr ID:
                                </FormLabel>
                                <Input
                                    placeholder="Nvr ID"
                                    borderColor="gray"
                                    borderRadius="10px"
                                    px={4}
                                    _placeholder={{ color: "gray.400" }}
                                    value={nvrId}
                                    onChange={(e) => setNvrId(e.target.value.replace(/\s/g, ""))}

                                />
                            </FormControl>
                            <FormControl width="350px" mt={5} isRequired>
                                <FormLabel
                                    htmlFor="device-name"
                                    textAlign="start"
                                >
                                    Nvr Channel:
                                </FormLabel>
                                <Input
                                    placeholder="NVR Channel"
                                    borderColor="gray"
                                    type='number'
                                    borderRadius="10px"
                                    px={4}
                                    _placeholder={{ color: "gray.400" }}
                                    value={nvrChannel}
                                    onChange={(e) => setNvrChannel(e.target.value.replace(/\s/g, ""))}
                                />
                            </FormControl>
                            <Box width="350px" textAlign={'start'}>
                                {error && <Text color="red.500">{error}</Text>}
                            </Box>

                        </Box>
                        {uploadStatus &&
                            <Text display={'flex'}
                                alignItems={'center'}
                                justifyContent={'center'}
                                color={'red'}>
                                <MdError />&nbsp;{uploadStatus}
                            </Text>}
                    </ModalBody>

                    <ModalFooter marginRight={"10px"} justifyContent={"space-evenly"}>
                        <Button
                            onClick={closeModal}
                            w="150px"
                            border="1px"
                            background="0"
                            color="red.500"
                            borderColor="red.500"
                            _hover={{ background: "none" }}
                        >
                            Cancel
                        </Button>

                        <Button
                            onClick={() => handleAddNvr()}
                            w="150px"
                            // background={useColorModeValue(
                            //     theme.colors.custom.primary,
                            //     theme.colors.custom.darkModePrimary
                            // )}
                            // color={useColorModeValue(
                            //     theme.colors.custom.lightModeText,
                            //     theme.colors.custom.darkModeText
                            // )}
                            fontWeight={"normal"}
                        // _hover={{
                        //     backgroundColor: useColorModeValue(
                        //         theme.colors.custom.darkModePrimary,
                        //         theme.colors.custom.primary
                        //     ),
                        //     color: useColorModeValue(
                        //         theme.colors.custom.darkModeText,
                        //         theme.colors.custom.lightModeText
                        //     ),
                        // }}
                        >
                            Add NVR
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
}

export default NvrList;
