import axios from 'axios';
import CryptoJS from "crypto-js";

const baseURL = 'https://ems.ambicam.com:5000/api/users';
// const baseURL = 'http://localhost:5000/api/users';

const instance = axios.create({
  baseURL: baseURL,
  withCredentials: true
});

instance.interceptors.response.use(
  response => {
    // If the response is successful, just return the response
    return response;
  },
  error => {
    // If the response has a status code of 401, redirect to the login page
    if (error.response && error.response.status === 401) {
      window.location.href = '/'; // Replace with your login route
    }
    // Otherwise, reject the promise with the error object
    return Promise.reject(error);
  }
);

const SECRET_KEY = "1234567890123456"
const IV = "abcdefghijklmnop"

export const login = async (email, password) => {
  try {
    // Encrypt the password using AES
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(password), CryptoJS.enc.Utf8.parse(SECRET_KEY), {
      iv: CryptoJS.enc.Utf8.parse(IV),
      mode: CryptoJS.mode.CBC,
      padding: CryptoJS.pad.Pkcs7,
    }).toString();

    // console.log("Encrypted Password:", ciphertext);

    const response = await instance.post('/login', {
      username: email,
      password: ciphertext,
    });
    // console.log("response", response);
    return response.data;
  } catch (error) {
    // Handle errors, and include an error message in the response
    return { success: false, message: error.response.data.message };
  }
};

export const logout = async () => {
  try {
    const response = await instance.get('/logout', {

    });

    return response.data;
  } catch (error) {
    // Handle errors, and include an error message in the response
    return { success: false, message: error.response.data.message };
  }
};